import React from "react"
import { Form, Select, Input, Button, DatePicker, InputNumber } from "antd"
import SelectComponent from "./SelectComponent"

const ServiceForm = props => {
  return (
    <div>
      <SelectComponent
        selectedServiceGroup={props.selectedServiceGroup}
        serviceGroup={props.serviceGroup}
        className="customer"
        placehoder="Select Service Group"
        mode="single"
        label="SERVICE GROUP"
        handleChange={v => props.handleServiceGroupChange(v)}
      />
      <Form
        className="servic_form_ds"
        style={{ marginTop: "-20px" }}
        onFieldsChange={e => {
          props.handleChange(e)
        }}
        onFinish={() => props.onSubmit()}
        form={props.form}
        className="form"
      >
        <div className="servic_form_ds_details_part">
          <div className="servic_form_ds_details_part_one">
            <Form.Item
              className="servic_form_ds_input_select_one"
              name="service"
              label="SERVICE"
              rules={[{ required: true }]}
            >
              <Select
                virtual={false}
                placeholder="Select Service"
                onChange={props.handleServiceChange}
                allowClear
                value={props.selectedService}
                style={{ width: "300px" }}
              >
                {props.services &&
                  props.services.map(item => {
                    return (
                      <Select.Option
                        key={item.param_value}
                        value={item.param_value}
                        key={item.param_value}
                      >
                        {item.param_description}
                      </Select.Option>
                    )
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              className="servic_form_ds_input_select_two"
              label="PRICE"
              name="Price"
              rules={[
                {
                  required: true,
                  message: "Please enter price",
                },
                { type: "number" },
                {
                  pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
                  message: "Price value should be greater than 0 number",
                },
              ]}
            >
              <InputNumber
                style={{ width: "300px", border: "1px solid grey" }}
                value={props.price}
                onChange={props.handlePriceChange}
                min={0}
                precision={2}
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Form.Item>
            <Form.Item
              className="servic_form_ds_input_select_three"
              label="TAX %"
              name="Tax"
              rules={[
                {
                  required: true,
                  message: "Please enter tax rate",
                },
                { type: "number" },
                {
                  pattern: /^[0-9]\d*$/,
                  message: "Tax value should be greater or equal to 0 number",
                },
              ]}
            >
              <InputNumber
                style={{ width: "300px" }}
                value={props.tax}
                onChange={props.handleTaxChange}
                min={0}
                precision={2}
              />
            </Form.Item>
          </div>

          <div className="servic_form_ds_details_part_two">
            <div className="servic_form_ds_details_part_two_1">
              {/* <Form.Item
                className="servic_form_ds_input_select_four"
                name="Valid from"
                label="VALID FROM"
                rules={[
                  { required: true, message: 'Please select valid from date' },
                ]}
              >
                <DatePicker
                  value={props.validateFrom}
                  disabledDate={(d) => d.isAfter(props.validateTo)}
                  onChange={props.handleChangeValidateFrom}
                />
              </Form.Item> */}
              {/* <Form.Item
                className="servic_form_ds_input_select_five"
                name="Valid To"
                label="VALID TO"
                rules={[
                  { required: true, message: 'Please select valid to date' },
                ]}
              >
                <DatePicker
                  value={props.validateTo}
                  disabledDate={(d) => d.isBefore(props.validateFrom)}
                  onChange={props.handleChangeValidateTo}
                />
              </Form.Item> */}
              <Form.Item
                className="servic_form_ds_input_select_six"
                name="Remark"
                label="REMARKS"
              >
                <Input.TextArea
                  value={props.remark}
                  onChange={props.handleRemarkChange}
                  className="textArea"
                  style={{ height: "180px", width: "450px", marginTop: "80px" }}
                />
              </Form.Item>
            </div>
            {/* <div className="servic_form_ds_details_part_two_2">
              
            </div> */}
          </div>
        </div>

        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          {props.formEdit && (
            <Button
              onClick={() => props.onCancelEdit()}
              style={{
                backgroundColor: "red",
                color: "#ffffff",
                marginRight: 20,
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            htmlType="submit"
            loading={props.loading}
            type="submit"
            style={{ backgroundColor: "#48A4F4", color: "#ffffff" }}
          >
            {props.formEdit ? "UPDATE" : "SUBMIT"}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default ServiceForm
